var render = function () {
var this$1 = this;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"brandProblemAdd-container"},[_c('el-drawer',{staticClass:"brandProblemAdd-drawer",attrs:{"title":_vm.type == 'add'
        ? _vm.level == 1
          ? '新增质检分类'
          : _vm.level == 2
          ? '新增问题点'
          : '新增问题说明'
        : _vm.level == 1
        ? '编辑质检分类'
        : _vm.level == 2
        ? '编辑问题点'
        : '编辑问题说明',"visible":_vm.drawer,"append-to-body":true,"direction":"rtl","before-close":_vm.handleClose},on:{"update:visible":function($event){_vm.drawer=$event}}},[_c('div',{staticClass:"drawer-container"},[_c('el-form',{ref:"numberValidateForm",staticClass:"demo-ruleForm",attrs:{"model":_vm.numberValidateForm,"label-width":"140px"}},[_c('el-form-item',{attrs:{"label":_vm.level == 1 ? '质检分类名称' : _vm.level == 2 ? '问题点名称' : '问题说明',"prop":"name","rules":[
            { required: true, message: ((_vm.level == 3 ? '问题说明' : '名称') + "不能为空") },
            { max: 100, message: '名称长度小于100 个字符' } ]}},[_c('el-input',{attrs:{"autosize":{ minRows: 4, maxRows: 4 },"type":"textarea"},model:{value:(_vm.numberValidateForm.name),callback:function ($$v) {_vm.$set(_vm.numberValidateForm, "name", $$v)},expression:"numberValidateForm.name"}})],1),_c('div',{staticClass:"formBtn"},[_c('el-button',{staticClass:"common-screen-btn",on:{"click":function () {this$1.$emit('handleClose');}}},[_vm._v("取消")]),_c('el-button',{staticClass:"common-screen-btn",attrs:{"type":"primary"},on:{"click":function($event){return _vm.submit()}}},[_vm._v("确认")])],1)],1)],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }